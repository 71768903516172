import {Box} from "@mui/material";
import {css, keyframes} from "@emotion/react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    backgroundContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
}));

const slide = keyframes`
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
`;

const bg = (theme) => css`
  position: absolute;
  animation: ${slide} 6s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #212121 50%, #121212 50%);
  z-index: -1;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
`;

const bg2 = (theme) => css`
  ${bg(theme)};
  animation-direction: alternate-reverse;
  animation-duration: 7s;
`

const bg3 = (theme) => css`
  ${bg(theme)};
  animation-duration: 8s;
`

export function Background() {
    const classes = useStyles()

    return (
        <Box className={classes.backgroundContainer}>
            <Box sx={bg}/>
            <Box sx={bg2}/>
            <Box sx={bg3}/>
        </Box>
    )
}
