import './App.css';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import {Home} from "./header/Home";

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: '\'Rajdhani\', sans-serif',
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="App">
                <header>
                    <Home/>
                </header>
            </div>
        </ThemeProvider>
    );
}

export default App;
