import {Background} from "./Background";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {css, keyframes} from "@emotion/react";

const useStyles = makeStyles(theme => ({
    appHeader: {
        width: '100vw',
        height: '100dvh',
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
    },
    brandContainer: {
        position: "relative",
        display: "flex",
        alignSelf: "center"
    },
    logo: {
        pointerEvents: "none",
        userSelect: "none",
        position: "absolute",
        height: "100%",
        marginLeft: "-3%",
        marginTop: "-3%",
    },
    selectNone: {
        pointerEvents: "none",
        userSelect: "none"
    }
}));

const trackingIn = keyframes`
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
`

const brand = (theme) => css`
  pointer-events: none;
  user-select: none;
  font-size: max(20vh, 6rem);
  animation: ${trackingIn} 0.8s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  ${theme.breakpoints.down("md")} {
    font-size: max(12vh, 6rem);
  }
`

const logoSlideIn = keyframes`
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
`

const logo = css`
  animation: ${logoSlideIn} 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2.5s both;
`

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const tagline = css`
  animation: ${slideIn} 1.3s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`

export function Home() {
    const classes = useStyles()

    return (
        <Box className={classes.appHeader}>
            <Background/>
            <Box className={`${classes.brandContainer} ${classes.selectNone}`}>
                <Typography variant={"h1"} sx={brand}>ARTIFACA</Typography>
                <Box component="img" className={classes.logo} sx={logo} src="/logo.png" alt="logo"
                     draggable={false}/>
            </Box>
            <Box className={classes.brandContainer}>
                <Typography variant={"h5"} sx={tagline}>The artist. The crafter. The Artifaca.</Typography>
            </Box>
        </Box>
    )
}
